import React from 'react'

import Wysiwyg from '@components/Layout/Wysiwyg'

import checkmark from '../../../images/check-secondary.svg'
import cross from '../../../images/cross-pink.svg'
import Section from '../Section/Section'
import * as styles from './Table.module.scss'

interface Props {
	data: {
		title: string
		intro: string
		financeTypes: [
			{
				name: string
				explaination: string
			}
		]
		outro: string
	}
}

const FinanceTable = (props: Props) => {
	const { data } = props

	const checkmarkImage = <img src={checkmark} alt="" />
	const crossImage = <img src={cross} alt="" />

	return (
		<Section className={styles.section} containerClassName={styles.container}>
			<div className={styles.left}>
				<h2 className={styles.title}>{data.title}</h2>

				<Wysiwyg content={data.intro} className={styles.content} />

				<ul className={[styles.list, styles.content].join(' ')}>
					{data.financeTypes.map((financeType) => {
						return (
							<li key={financeType.name}>
								<strong>{financeType.name}</strong> - {financeType.explaination}
							</li>
						)
					})}
				</ul>

				<Wysiwyg content={data.outro} className={styles.content} />
			</div>
			<div className={styles.right}>
				<table className={styles.table}>
					<thead>
						<tr>
							<td>Features:</td>
							<td>Hire Purchase (HP)</td>
							<td>Personal Contract Purchase (PCP)</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Required initial deposit</td>
							<td>Optional</td>
							<td>Optional</td>
						</tr>
						<tr>
							<td>Car is yours at the end of the agreement</td>
							<td>{checkmarkImage}</td>
							<td>Optional</td>
						</tr>
						<tr>
							<td>Fixed monthly payments</td>
							<td>{checkmarkImage}</td>
							<td>{checkmarkImage}</td>
						</tr>
						<tr>
							<td>Avoid (final) balloon payment</td>
							<td>{checkmarkImage}</td>
							<td>{crossImage}</td>
						</tr>
						<tr>
							<td>Avoid excess mileage charge</td>
							<td>{checkmarkImage}</td>
							<td>{crossImage}</td>
						</tr>
						<tr>
							<td>Secured against an asset (e.g. a car)</td>
							<td>{checkmarkImage}</td>
							<td>{checkmarkImage}</td>
						</tr>
						<tr>
							<td>Support with vehicle issues</td>
							<td>{checkmarkImage}</td>
							<td>{checkmarkImage}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Section>
	)
}

export default FinanceTable
