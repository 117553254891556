import React from 'react'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import Container from '@components/Layout/Container'
import Wysiwyg from '@components/Layout/Wysiwyg'
import HeroWidget from '@components/Pages/Homepage/HeroWidget'

import * as styles from './Hero.module.scss'

import scrollArrowDown from '@images/scroll-arrow-down.svg'

interface Props {
	data: {
		title: string
		subtext: string
		image: {
			localFile?: {
				childImageSharp: {
					gatsbyImageData: IGatsbyImageData
				}
			}
		}
	}
}

const Hero = (props: Props) => {
	const [heroHeight, setHeroHeight] = React.useState(0)
	const heroRef = React.useRef<HTMLDivElement>(null)

	React.useEffect(() => {
		setHeroHeight(heroRef.current?.clientHeight || 0)
	}, [])

	return (
		<section className={styles.hero} ref={heroRef}>
			<Container class={styles.container}>
				<div>
					<h1 className={styles.title}>{props.data.title}</h1>
					<Wysiwyg className={styles.content} content={props.data.subtext} />
				</div>
				<HeroWidget />

				<img
					src={scrollArrowDown}
					alt=""
					className={styles.arrowDown}
					onClick={() => {
						if (window) {
							window.scrollTo({
								top: heroHeight,
								behavior: 'smooth',
							})
						}
					}}
				/>
			</Container>

			{props.data.image.localFile && (
				<GatsbyImage
					image={props.data.image.localFile.childImageSharp.gatsbyImageData}
					alt=""
					className={styles.backgroundImage}
					loading="eager"
				/>
			)}
		</section>
	)
}

export default Hero
