// extracted by mini-css-extract-plugin
export var active = "HeroWidget-module--active--e1075";
export var button = "HeroWidget-module--button--98dfc";
export var content = "HeroWidget-module--content--eecac";
export var controls = "HeroWidget-module--controls--8f749";
export var decrease = "HeroWidget-module--decrease--0b492";
export var increase = "HeroWidget-module--increase--c2c74";
export var title = "HeroWidget-module--title--fc5b6";
export var value = "HeroWidget-module--value--96cd3";
export var vehicle = "HeroWidget-module--vehicle--e461f";
export var vehicles = "HeroWidget-module--vehicles--e8d94";
export var widget = "HeroWidget-module--widget--d1048";