import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import CountersSection from '@components/Content/Counters/CountersSection'
import Feature from '@components/Content/Feature/Feature'
import FinanceTable from '@components/Content/Finance/Table'
import PageLinks from '@components/Content/PageLinks/PageLinks'
import QuoteCTA from '@components/Content/QuoteCTA/QuoteCTA'
import Section from '@components/Content/Section/Section'
import USPGrid from '@components/Content/USP/USPGrid'
import DealsSection from '@components/Deals/DealsSection'
import FAQSection from '@components/FAQ/FAQSection'
import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'
import OverTheMoon from '@components/OverTheMoon/OverTheMoon'
import Hero from '@components/Pages/Homepage/Hero'
import Parallax from '@components/Parallax/Parallax'
import TestimonialsSection from '@components/Testimonials/TestimonialsSection'

import { wpHomepageProps } from '@contracts/page'

export const Homepage = (props: wpHomepageProps) => {
	const { data } = props.pageContext
	const { counters, feature, pageLinks, typesOfFinance, overTheMoon, parallaxImage } = data.homepageOptions

	const featuredImageBkp = feature && feature.image && feature.image.mediaItemUrl ? feature.image.mediaItemUrl : ''
	const featuredImage =
		feature &&
		feature.image &&
		feature.image.localFile &&
		feature.image.localFile.childImageSharp &&
		feature.image.localFile.childImageSharp.gatsbyImageData
			? feature.image.localFile.childImageSharp.gatsbyImageData
			: null

	const ParallaxImage = <GatsbyImage image={parallaxImage.localFile?.childImageSharp.gatsbyImageData} alt="" />

	return (
		<Layout pathname={'/'} template="home">
			<Parallax background={<Hero data={data.homepageOptions.hero} />} curve={true} stickyImage={ParallaxImage}>
				<CountersSection counters={counters.counters} />

				<Section noPaddingBottom>
					<Feature title={feature.title} image={featuredImage} imageBkp={featuredImageBkp} content={feature.content} />
				</Section>

				<Section noPaddingBottom>
					<OverTheMoon data={overTheMoon} />
				</Section>

				<Section noPaddingBottom>
					<USPGrid />
				</Section>

				<Section noPaddingBottom>
					<PageLinks links={pageLinks.links} />
				</Section>

				<FinanceTable data={typesOfFinance} />

				<TestimonialsSection title="What our customers say" />

				<FAQSection />

				<DealsSection />

				<QuoteCTA />
			</Parallax>
		</Layout>
	)
}

export function Head(props: wpHomepageProps) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.seo} />
		</>
	)
}

export default Homepage
