import React from 'react'

import Button from '@components/Content/Button/Button'

import * as styles from './HeroWidget.module.scss'

const HeroWidget = () => {
	const [calculatorAmount, setCalculatorAmount] = React.useState(250)
	const [vehicleType, setVehicleType] = React.useState('car')

	const handleButtonChange = (type: 'increase' | 'decrease') => {
		if (type === 'increase') {
			setCalculatorAmount(calculatorAmount + 25)
		}

		if (type === 'decrease' && calculatorAmount > 25) {
			setCalculatorAmount(calculatorAmount - 25)
		}
	}

	return (
		<div className={styles.widget}>
			<div className={styles.content}>
				<div>
					<span className={styles.title}>My monthly budget is...</span>
				</div>

				<div className={styles.controls}>
					<button
						className={[styles.button, styles.decrease].join(' ')}
						onClick={() => handleButtonChange('decrease')}
					></button>
					<div className={styles.value}>
						<span>£</span> <span>{calculatorAmount}</span>
					</div>
					<button
						className={[styles.button, styles.increase].join(' ')}
						onClick={() => handleButtonChange('increase')}
					></button>
				</div>

				<div>
					<span className={styles.title}>To finance a...</span>
				</div>

				<div className={styles.vehicles}>
					<div className={styles.vehicle}>
						<img
							src="/images/calculator-car.svg"
							alt=""
							onClick={() => setVehicleType('car')}
							className={vehicleType === 'car' ? styles.active : ''}
						/>
					</div>
					<div className={styles.vehicle}>
						<img
							src="/images/calculator-van.svg"
							alt=""
							onClick={() => setVehicleType('van')}
							className={vehicleType === 'van' ? styles.active : ''}
						/>
					</div>
					<div className={styles.vehicle}>
						<img
							src="/images/calculator-bike.svg"
							alt=""
							onClick={() => setVehicleType('motorbike')}
							className={vehicleType === 'motorbike' ? styles.active : ''}
						/>
					</div>
				</div>

				<div>
					<Button
						uri={`/apply?MonthlyBudget=${calculatorAmount}&VehicleType=${vehicleType}`}
						label="Start your quote"
					/>
				</div>
			</div>
		</div>
	)
}

export default HeroWidget
